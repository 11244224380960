import React from "react";

import BlockContent from "@sanity/block-content-to-react";

import { Serializer } from "src/utils/serializer";
import { Image } from "src/components/image";
import { ProductForm } from "./form";

export const ProductHero = ({
  product,
  main: { title, productDescription, config, imageSet }
}: {
  main: {
    title?: string;
    subTitle?: string;
    config?: [];
    imageSet?: [];
    slug: {};
    productDescription?: [];
  };
  product: {
    defaultPrice: string;
    productId: number;
    image: {
      asset: {
        _id: string;
      };
    };
  };
}) => {
  return (
    <div>
      <div className="max-w-6xl mx-auto w-full">
        <div className="flex flex-wrap justify-between text-left items-start">
          <div className="md:w-1/2 w-full md:sticky md:top-20 md:py-3">
            {product.image && (
              <Image
                className="w-full"
                imageId={product.image.asset._id}
                alt={title}
              />
            )}
          </div>
          <div className="md:w-1/2 max-w-md mx-auto px-4 py-3">
            <h1 className="mb-2">{title}</h1>
            {productDescription && (
              <BlockContent
                blocks={productDescription}
                serializers={Serializer}
              />
            )}
            <ProductForm
              {...product}
              config={config || []}
              showQuantity={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
