import React, { useEffect, useState } from "react";
import { encode, decode } from "shopify-gid";
import cx from "classnames";

import { Waitlist } from "src/components/product/waitlist";
import { client, useAddItemToCart } from "src/context/siteContext";
import { Image } from "src/components/image";

export const ProductForm = ({
  slug,
  defaultPrice,
  productId,
  showQuantity,
  waitlist = true,
  addText,
  config
}: {
  defaultPrice: string;
  slug?: {
    current: string;
  };
  productId: number;
  waitlist?: boolean | true;
  showQuantity?: boolean | true;
  addText?: string;
  config: [];
}) => {
  const addItemToCart = useAddItemToCart();

  const [quantity, setQuantity] = useState(1 as number);
  const [adding, setAdding] = useState(false as boolean);
  const [price, setPrice] = useState(defaultPrice);
  const [available, setAvailable] = useState(false);
  const [variants, setVariants] = useState([]);
  const [activeVariantId, setActiveVariantId] = useState("" as string);
  const [compareAtPrice, setCompareAtPrice] = useState(
    undefined as string | undefined
  );
  const [check, setCheck] = useState(true);
  const [attributes, setAttributes] = useState([]);

  const [gift, setGift] = useState({
    giftEmail: "",
    giftMessage: ""
  });

  const form = React.createRef();

  useEffect(() => {
    if (check) {
      const shopifyId = encode("Product", productId, {
        accessToken: process.env.GATSBY_SHOPIFY_TOKEN
      });

      client.product.fetch(shopifyId).then((product: any) => {
        const decodedVariants = [] as any;
        product.variants.forEach((variant: any) => {
          decodedVariants.push({
            ...variant,
            cleanId: parseInt(decode(variant.id).id, 0)
          });
        });

        setVariants(decodedVariants);
        setActiveVariantId(decodedVariants[0].id as string);
        setAvailable(decodedVariants[0].available);

        if (decodedVariants[0].compareAtPrice)
          setCompareAtPrice(decodedVariants[0].compareAtPrice);

        setCheck(false);
      });
    }
  }, [check]);

  useEffect(() => {
    setAttributes(
      config.map(({ title, materials }) => {
        return {
          key: title,
          value: materials[0].content.technicalTitle
        };
      })
    );
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setAdding(true);
    if (available) {
      addItemToCart(activeVariantId, quantity, attributes).then(() => {
        setAdding(false);
      });
    }
  };

  const handleAttributeSelect = (e, name, value) => {
    e.preventDefault();
    e.stopPropagation();
    const newAttributes = attributes.map(attr =>
      attr.key === name
        ? {
            key: name,
            value: value
          }
        : attr
    );
    setAttributes(newAttributes);
  };

  const handleChange = (e: React.FormEvent) => {
    setActiveVariantId(e.target.value);
    variants.forEach(variant => {
      if (variant.id === e.target.value) {
        if (variant.compareAtPrice) {
          setCompareAtPrice(variant.compareAtPrice);
        }
        setPrice(variant.price);
      }
    });
  };

  const handleGiftChange = e => {
    setGift({ ...gift, [e.target.name]: e.target.value });
  };

  return (
    <div className="max-w-md">
      <form onSubmit={e => handleSubmit(e)} ref={form}>
        {available && !check ? (
          <div className="w-full border-t border-b border-black py-3 mb-4">
            <p className="text-sm">
              This is a custom made-to-order bag. Select your materials below
            </p>
            {config.map(({ title, materials }) => (
              <div className="mb-4">
                <h5 className="mb-3 leading-none flex items-center md:items-start flex-col md:flex-row">
                  {title}:
                  <span className="font-medium pl-2">
                    {attributes.find(attr => attr.key === title).value}
                  </span>
                </h5>
                <div className="flex flex-wrap items-start justify-center md:justify-start">
                  {materials.map(material => {
                    const isSelectedAttribute = Boolean(
                      attributes.find(
                        attr =>
                          attr.key === title &&
                          attr.value === material.content.technicalTitle
                      )
                    );
                    return (
                      <button
                        className={cx(
                          "w-24 p-1 block border-2 border-transparent transition-all duration-150 mx-1",
                          {
                            "hover:border-accent opacity-50": !isSelectedAttribute,
                            "border-black": isSelectedAttribute
                          }
                        )}
                        onClick={e =>
                          handleAttributeSelect(
                            e,
                            title,
                            material.content.technicalTitle
                          )
                        }
                      >
                        <Image imageId={material.content.image.asset._id} />
                        <label className="text-xs leading-4 block">
                          {material.content.title}
                        </label>
                      </button>
                    );
                  })}
                </div>
              </div>
            ))}
            {variants.length > 1 && (
              <div className="w-full mb-4 py-3">
                <select
                  onChange={handleChange}
                  className="p-2 w-full bg-white border"
                >
                  {variants.map(
                    ({
                      id,
                      title,
                      available
                    }: {
                      id: string;
                      title: string;
                      available: boolean;
                    }) => (
                      <option disabled={!available} key={id} value={id}>
                        {title}
                      </option>
                    )
                  )}
                </select>
              </div>
            )}

            <div className="flex flex-col sm:flex-row justify-start items-stretch">
              {showQuantity && (
                <div className="flex justify-between items-center">
                  <div className="flex justify-between p-2 items-center mx-auto bg-white w-full">
                    <button
                      type="button"
                      className="appearance-none p-2 mr-2"
                      onClick={() =>
                        quantity === 1 ? null : setQuantity(quantity - 1)
                      }
                    >
                      -
                    </button>
                    <input
                      type="number"
                      value={quantity}
                      onChange={e =>
                        setQuantity(parseInt(e.currentTarget.value, 10))
                      }
                      name="quantity"
                      min="1"
                      className="text-center border w-full sm:w-20"
                    />
                    <button
                      type="button"
                      className="appearance-none p-2 ml-2"
                      onClick={() => setQuantity(quantity + 1)}
                    >
                      +
                    </button>
                  </div>
                </div>
              )}
              <button type="submit" className="px-4 w-full btn--primary">
                <span>
                  {adding ? "Adding" : addText ? addText : "Add to Cart"}
                </span>
                {compareAtPrice && (
                  <span className="text-bold ml-2 line-through">
                    ${parseFloat(compareAtPrice * quantity)}
                  </span>
                )}
                <span className="text-bold ml-2">
                  ${parseFloat(price * quantity)}
                </span>
              </button>
            </div>
          </div>
        ) : (
          <div>
            {available ? (
              <span>Checking Stock</span>
            ) : waitlist ? (
              <div className="mt1 pt1">
                <h5>Get notifed when stock is replenished</h5>
                <Waitlist
                  accountId="KKfBYU"
                  message="Got it! We'll update you when it's back"
                  buttonText="Notify Me"
                  variantId={activeVariantId}
                />
              </div>
            ) : (
              // Left empty for now
              <div className="ac x bold">
                <span className="small" />
              </div>
            )}
          </div>
        )}
      </form>
    </div>
  );
};
